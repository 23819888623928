import React from 'react';
import { FooterBoxContent } from '../layout/Footer';
import Layout from '../layout/Layout';

const Redirect = (): JSX.Element => {
  return (
    <Layout footerBoxContent={FooterBoxContent.None} title="Redirecting...">
      Redirecting... Please wait
    </Layout>
  );
};

export default Redirect;
